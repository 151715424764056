import styled from "styled-components";
import {
  Footer,
  Header,
  FAQ,
  Qualifications,
  SelectionProcess,
  Goals,
  Team,
  ProgramMission,
  ApplicationClosed,
  Application,
} from "../components";
import Background from "assets/images/bg-world.jpg";
import MailingListSection from "components/MailingListSection";
import LetterFromDirector from "components/LetterFromDirector";
import { APPLICATIONS_OPEN } from "config/Config";
import { useEffect } from "react";
import WorldBanner from "components/WorldBanner";

const Wrapper = styled.div`
  background-position: center 8%;
  background-image: url(${Background});
  background-repeat: no-repeat;

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    background-position: center 10%;
  }
  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    background-position: center 5%;
  }
`;

const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Wrapper className="w-full">
        <WorldBanner />
        <Goals />
        <ProgramMission />
        <SelectionProcess />
        <Qualifications />
        <FAQ />
      </Wrapper>
      <Team />
      <LetterFromDirector />
      {!APPLICATIONS_OPEN && (
        <>
          <MailingListSection />
        </>
      )}
      <ApplicationClosed />
      {APPLICATIONS_OPEN && <Application />}
      <Footer />
    </>
  );
};

export default Home;
