import clsx from "clsx";
import { APPLICATIONS_OPEN, TYPEFORM_LINK } from "config/Config";
import { useState } from "react";
import styled from "styled-components";
import MailingListForm from "./MailingListForm";

const BannerSection = styled.section`
  width: 100%;
  padding-top: 280px;
  position: relative;
  padding-bottom: 80px;
  margin-bottom: 50px;

  .content {
    position: relative;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    &.isOpen {
      flex-direction: column;
    }
    &.center {
      align-items: center;
    }
    .button-wrapper {
      margin-top: 58px;
    }
    .text {
      width: 100%;
      max-width: 720px;
      h1 {
        font-family: "new-order", sans-serif;
        font-size: 92px;
        font-weight: 700;
        line-height: 110px;
        text-align: left;
        color: ${({ theme }) => theme.colors.secondary};
      }
      h3 {
        font-family: "new-order", sans-serif;
        font-size: 40px;
        font-weight: 400;
        line-height: 54px;
        color: ${({ theme }) => theme.colors.secondary};
        opacity: 0.8;
      }
      p {
        font-family: Neue-Haas, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        color: ${({ theme }) => theme.colors.secondary};
        opacity: 0.6;
        b {
          font-weight: 700;
        }
      }
    }
    .subscribe-mailing-list {
      width: 100%;
      max-width: 450px;
      background: rgba(255, 255, 255, 0.05);
      box-shadow: 0px 0px 20px rgba(59, 144, 190, 0.4);
      border-radius: 10px;
      margin-left: 28px;
      border: 1px solid #3b90be;
      padding: 28px 28px 45px 28px;
      &.zero-padding {
        padding: 0;
      }
      h2 {
        color: #fff;
        font-family: "new-order", sans-serif;
        font-weight: 600;
        font-size: 19px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.02em;
        margin-bottom: 20px;
      }
    }
  }
  .submit-success-screen {
    padding: 35px 50px;
  }
  .circle-shadow {
    position: absolute;
    bottom: -500px;
    left: -300px;
  }
  @media ${({ theme }) => theme.mediaQueries.bellowDesktop} {
    .content {
      flex-direction: column;
      .subscribe-mailing-list {
        margin: 0;
      }
    }
  }
  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding-top: 130px;
    .content {
      .text {
        padding-right: 30px;
        h1 {
          font-size: 36px;
          line-height: 43px;
          word-spacing: 100vw;
        }
        h3 {
          font-size: 18px;
          line-height: 24px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
      .button-wrapper {
        padding: 0;
        .main-button {
          width: 100%;
          display: block;
          text-align: center;
        }
      }
    }
    .circle-shadow {
      position: absolute;
      bottom: -350px;
      left: -200px;
    }
  }
`;

const Banner: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  return (
    <BannerSection id="main" className="main-width">
      <div className={clsx("content", isSubmitted && "center", APPLICATIONS_OPEN && "isOpen")}>
        <div className="text">
          <h1>776 Fellowship</h1>
          <h3>The 776 Fellowship Program is a two-year program for young people who want to build a better future.</h3>
          <p>
            776 Fellows receive up to a <b>$100,000</b> grant and support from the Seven Seven Six network.
          </p>
        </div>

        {APPLICATIONS_OPEN ? (
          <div className="button-wrapper">
            <a href={TYPEFORM_LINK} target="_blank" rel="noreferrer" className="main-button">
              Apply Now
            </a>
          </div>
        ) : (
          <div className={clsx("subscribe-mailing-list", "box-wrapper", isSubmitted && "zero-padding")}>
            {!isSubmitted && <h2>Submit your email we'll notify you when applications open for our 2025 Cohort!</h2>}
            <MailingListForm onSubmit={() => setIsSubmitted(true)} />
          </div>
        )}
      </div>
    </BannerSection>
  );
};

export default Banner;
