import React from "react";
import styled from "styled-components";
import Countdown from "./Countdown";
import { APPLICATIONS_OPEN, TYPEFORM_LINK } from "config/Config";

const WorldBannerSection = styled.section`
  padding: 280px 20px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    padding: 135px 20px 0 20px;
  }

  .banner-content {
    text-align: center;
    color: ${({ theme }) => theme.colors.white};

    h1 {
      font-size: 62px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
        font-size: 42px;
      }
    }

    .banner-subtitle {
      display: none;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 54px; /* 150% */
      letter-spacing: 0.72px;

      @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
        display: block;
      }

      @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
        font-size: 21px;
        line-height: 29px;
        margin-top: 10px;
      }
    }

    .banner-text {
      max-width: 620px;
      margin: 0 auto;
      opacity: 0.6;

      font-family: Neue-Haas, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      letter-spacing: 0.32px;

      @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
        margin-top: 20px;
      }

      @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
        font-size: 14px;
      }

      p {
        margin: 0;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }

  .countdown-container {
    margin-top: 40px;

    @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
      margin-top: 30px;
    }

    @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
      margin-top: 24px;
      width: 100%;
    }
  }

  .button-wrapper {
    margin-top: 40px;

    @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
      margin-top: 30px;
    }
    @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
      margin-top: 35px;
    }

    .apply-now-button {
      font-family: "new-order", sans-serif;
      padding: 16px 24px;
      border-radius: 24px;
      background-color: ${({ theme }) => theme.colors.white};
      color: #050c07;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: block;
      width: 201px;
      text-align: center;
    }
  }
`;

const WorldBanner: React.FC = () => {
  return (
    <WorldBannerSection>
      <div className="banner-content">
        <h1>776 Fellowship</h1>
        <h2 className="banner-subtitle">
          Fellowship Program is a two-year program for young people who want to build a better future.
        </h2>
        <div className="banner-text">
          <p>776 Fellows receive up to a $100,00 grant and support from the Seven Seven Six network.</p>
          <p>Don't miss your chance to apply!</p>
        </div>
      </div>

      <div className="countdown-container">
        <Countdown />
      </div>

      {APPLICATIONS_OPEN && (
        <div className="button-wrapper">
          <a href={TYPEFORM_LINK} target="_blank" rel="noreferrer" className="apply-now-button">
            Apply Now
          </a>
        </div>
      )}
    </WorldBannerSection>
  );
};

export default WorldBanner;
