import { FC } from "react";
import styled from "styled-components";
import DirectorSignature from "assets/images/director-signature-2.png";

const LetterFromDirectorDiv = styled.div`
  display: block;
  width: 789px;
  max-width: 100%;
  padding: 150px 20px;
  margin: 0 auto;
  color: #fff;
  padding-bottom: 100px;
  font-family: "Neue-Haas";

  h2 {
    font-size: 30px;
    line-height: 1.6;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 50px;
    font-family: "new-order";
  }

  h4 {
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 25px;
  }

  .text {
    color: #cecfd0;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 2%;
  }

  span {
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin-top: 15px;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding: 50px 20px;
  }

  .signature {
    max-width: 226px;
    height: auto;
  }
`;

const LetterFromDirector: FC = () => {
  return (
    <LetterFromDirectorDiv id="letter-from-director">
      <h2>A Letter from our Director</h2>

      <h4>2024 776 Fellowship Year in Review: A Testament to Youth-Led Climate Innovation</h4>

      <div className="text">
        <p>
          As 2024 comes to a close, the urgency to address the climate crisis feels more pressing than ever. Around the
          world, the need for transformative solutions—spanning nonprofit action and cutting-edge climate tech—continues
          to grow. At the 776 Foundation, we are inspired daily by the young leaders in our climate fellowship who are
          rising to meet this challenge with creativity, ingenuity, and determination.
        </p>

        <p>
          The 776 Climate Fellowship, which provides $100K grants over two years to 18-24-year-old climate founders, now
          boasts a community of 60 fellows across our three cohorts. Together, these changemakers are shaping the future
          of our planet. This year marked a significant milestone as our inaugural class concluded their two years with
          us, collectively raising over $80 million in funding across nonprofits and venture-backed initiatives. Among
          them, <b>Sasankh Munukutla</b> stands out: his company,{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.axios.com/pro/climate-deals/2024/12/12/terradot-launch-funding-erw"
          >
            Terradot
          </a>
          , announced $58.2M in funding and landmark carbon removal deals. Terradot's ambitious plan to scale
          gigaton-level CO2 removal technologies within the decade exemplifies the transformative potential of climate
          tech and highlights the extraordinary accomplishments of our fellows. And we were his very first $100,000.
        </p>

        <p>
          Our 2023 cohort continues to lead with groundbreaking ideas, including{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.forbes.com/30-under-30/2025/manufacturing-industry?profile=general-biological"
          >
            <b>Abhi Godavarthi</b>
          </a>
          , cofounder and CEO of General Biological, who is reshaping the petrochemical industry with bio-based
          alternatives made from agricultural waste, backed by $6.6M in funding from top tier investors like
          agricultural waste, backed by $6.6M in funding from top tier investors like Lowercarbon Capital. On the
          nonprofit front,{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.vogue.com/article/wawa-gatheru-earth-day-essay"
          >
            {" "}
            <b>Wawa Gatheru</b>{" "}
          </a>{" "}
          , founder of Black Girl Environmentalist, has received recognition from the New York Times, L'Oréal, and
          Forbes for her impactful work empowering Black girls, women and gender-expansive people in the climate
          movement.
        </p>

        <p>
          Our newest cohort of fellows, who began their fellowship in 2024, brings fresh perspectives and bold ideas to
          the fight against climate change. Among them,{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.bloomberg.com/news/videos/2024-07-11/xiye-bastida-on-an-indigenous-lens-of-climate-change-video"
          >
            <b>Xiye Bastida</b>
          </a>
          , co-founder and Executive Director of the Re-Earth Initiative, is driving youth-led climate action across 27
          countries, while <b>Emilio Molina</b>, founder of Semion, is advancing agricultural resilience through
          innovative pest defense technologies.
        </p>

        <p>
          This year, five fellows from across cohorts earned spots on the <b>Forbes 30 Under 30</b> list, further
          showcasing the influence of this growing network of changemakers.
        </p>

        <p>
          As I reflect on 2024, I am deeply grateful to our President, Alexis Ohanian, for letting me serve as the
          Director of the Foundation but specifically this Fellowship. It is a privilege to work alongside these
          brilliant young founders, whose projects range from creating biodegradable jewelry to recycling waste into
          nuclear energy and designing sustainable solutions for off-grid communities. Their creativity and
          determination set a powerful example for us all.
        </p>

        <p>
          Looking ahead, the 776 Foundation Climate Fellowship continues to be a testament to the extraordinary
          potential of youth-led climate action. Together, these fellows are building a brighter, more sustainable
          future—and inspiring the world to join them in this critical mission.
        </p>

        <p>Empowering Today's Youth for Tomorrow's World</p>
      </div>

      <img src={DirectorSignature} className="signature" alt="Fellowship Director's signature" />

      <span>Elisabeth Garvin</span>
    </LetterFromDirectorDiv>
  );
};

export default LetterFromDirector;
