import { ThemeProvider } from "styled-components/macro";

interface ThemeProps {
  children: JSX.Element;
}

export interface ITheme {
  colors: {
    white: string;
    primary: string;
    secondary: string;
    danger: string;
    neutralDanger: string;
    background: string;
    buttonColor: string;
    text: string;
    inputBorder: string;
    textLight: string;
    textDark: string;
    hoverLight: string;
    borderDark: string;
    hoverDark: string;
    success: string;
  };
  mediaQueries: {
    phone: string;
    tabletProtrait: string;
    tabletLandscape: string;
    desktop: string;

    bellowPhone: string;
    belowPhoneSmall: string;
    bellowTabletPortrait: string;
    bellowTabletLandscape: string;
    bellowDesktop: string;
  };
}

const theme: ITheme = {
  colors: {
    white: "#FFFFFF",
    primary: "#000",
    secondary: "#FFFFFF",
    danger: "#D40000",
    neutralDanger: "#f92f60",
    success: "#00d26a",
    background: "#000",
    buttonColor: "#BFCCD3",
    text: "#6C7581",
    inputBorder: "#BDCCD4",
    textLight: "#959595",
    textDark: "#313B45",
    borderDark: "#212121",
    hoverLight: "#CCCCCC",
    hoverDark: "#1a1a1a",
  },
  mediaQueries: {
    phone: "only screen and (min-width: 576px)",
    tabletProtrait: "only screen and (min-width: 768px)",
    tabletLandscape: "only screen and (min-width: 1024px)",
    desktop: "only screen and (min-width: 1280px)",
    bellowPhone: "only screen and (max-width: 575.98px)",
    belowPhoneSmall: "only screen and (max-width: 375.98px)",
    bellowTabletPortrait: "only screen and (max-width: 767.98px)",
    bellowTabletLandscape: "only screen and (max-width: 1023.98px)",
    bellowDesktop: "only screen and (max-width: 1279.98px)",
  },
};

const Theme = ({ children }: ThemeProps): JSX.Element => {
  return <ThemeProvider theme={{ ...theme }}>{children}</ThemeProvider>;
};

export default Theme;
