import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const CountdownDisplay = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;

  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    padding: 20px 0 20px 0;
    margin: 0 auto;
    max-width: 375px;
  }

  @media ${({ theme }) => theme.mediaQueries.belowPhoneSmall} {
    width: 100%;
  }
`;

const TimeUnit = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    flex: 1;
  }
`;

const TimeValue = styled.div`
  width: 120px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    width: 100%;
    height: 41px;
    font-size: 35px;
  }
`;

const TimeLabel = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

const Divider = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  width: 1px;
  height: 27px;
  margin: 0 10px;

  @media ${({ theme }) => theme.mediaQueries.bellowPhone} {
    margin: 0 5px;
  }
`;

const Countdown: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0 });
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Create a UTC date for April 14, 2025 at 00:00:00
    const targetDate = Date.UTC(2025, 3, 14, 0, 0); // Month is 0-indexed, so 3 = April
    // const targetDate = Date.now(); // Month is 0-indexed, so 3 = April

    const calculateTimeLeft = () => {
      // Get current time in UTC
      const now = Date.now();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

        setTimeLeft({ days, hours, minutes });
      } else {
        // Countdown is over, set all values to zero
        setTimeLeft({ days: 0, hours: 0, minutes: 0 });

        // Clear the interval when countdown is over
        if (timerRef.current) {
          clearInterval(timerRef.current);
          timerRef.current = null;
        }
      }
    };

    // Initial calculation
    calculateTimeLeft();

    // Setup the interval
    timerRef.current = setInterval(calculateTimeLeft, 60000);

    // Cleanup function to clear the interval when component unmounts
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  return (
    <CountdownDisplay>
      <TimeUnit>
        <TimeValue>{String(timeLeft.days).padStart(2, "0")}</TimeValue>
        <TimeLabel>Days</TimeLabel>
      </TimeUnit>
      <Divider />
      <TimeUnit>
        <TimeValue>{String(timeLeft.hours).padStart(2, "0")}</TimeValue>
        <TimeLabel>Hours</TimeLabel>
      </TimeUnit>
      <Divider />
      <TimeUnit>
        <TimeValue>{String(timeLeft.minutes).padStart(2, "0")}</TimeValue>
        <TimeLabel>Minutes</TimeLabel>
      </TimeUnit>
    </CountdownDisplay>
  );
};

export default Countdown;
