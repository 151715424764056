import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Minus } from "assets/icons/minus.svg";

const FAQSection = styled.section`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 9;
  padding-top: 100px;
  padding-bottom: 100px;
  .standard-title {
    padding-top: 15px;
    margin-right: 30px;
  }
  .list {
    width: 100%;
    max-width: 800px;
    .item {
      position: relative;
      border-bottom: 1px solid #191919;
      .header {
        display: flex;
        justify-content: space-between;
        padding: 25px 0;
        cursor: pointer;
        h3 {
          font-family: "new-order", sans-serif;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          color: ${({ theme }) => theme.colors.secondary};
          margin: 0 10px 0 0;
        }
      }
      p {
        max-height: 0;
        overflow: hidden;
        font-family: Neue-Haas, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.02em;
        color: ${({ theme }) => theme.colors.hoverLight};
        margin: 0;
        transition: all ease 0.3s;
      }
      &.open {
        p {
          max-height: 1000px;
          padding-bottom: 30px;
          padding-right: 45px;
        }
      }
    }
  }
  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    padding-top: 30px;
    padding-bottom: 20px;
    flex-direction: column;
    .list {
      .item {
        .header {
          h3 {
            font-size: 18px;
            line-height: 22px;
          }
        }
        p {
          font-size: 14px;
          line-height: 20px;
          padding-right: 35px;
        }
      }
    }
  }
`;

const items = [
  {
    title: "What is the Fellowship?",
    text: "The 776 Fellowship is a two-year, full-time program for 18 - 24 year olds, across the world. Fellows will receive a $100,000 grant over two years to work on projects to fight climate change instead of pursuing a university degree.",
  },
  {
    title: "When you say access to the 776 network, what does that mean?",
    text: "776 Fellows will have access to founder networks, various programming, and access to Cerebro (our venture fund's operating system).",
  },
  {
    title: "What kind of ideas is the fellowship looking for? ",
    text: "We are looking for a wide range of ideas, as climate change is a technological, social, and political issue.",
  },
  {
    title: "Do I need a team to apply?",
    text: "No, if you're working on a great idea or project, we're interested in hearing from you, however, if you already have a partner/co-founder, you can apply together.",
  },
  {
    title: "Do I need to be US based?",
    text: "No, the Fellowship is open to international applicants. We will be selecting applicants through a final remote interview.",
  },
  {
    title: "What do I spend the money from the grant on?",
    text: "Whatever you need to bring your idea to life over the next 2 years. If you need to be told exactly how to spend this money, this Fellowship likely isn't for you.",
  },
  {
    title: "If I have a 501(c)(3) can I still apply?",
    text: "Yes and alternatively if you're currently working on a project with a 501(c)(3) you can apply as well.",
  },
  {
    title: "Can I apply to the Fellowship if I'm still in school?",
    text: "This is a full-time program, so we're looking for applicants who are not currently enrolled in school or want to take two years away from school to focus on their project, company or idea. You can still be enrolled when you apply, but we'd expect you to drop out if you get accepted.",
  },
  {
    title: "How many Fellows do you select per cohort?",
    text: "Twenty.",
  },
  {
    title: "How does the grant work?",
    text: "Once you are accepted, we'll begin onboarding you with the rest of your cohort. Every quarter you'll receive part of your payment.",
  },
  {
    title: "When does the Cohort Start?",
    text: "Our 4th Cohort will be selected May 2025, with a start date of June 2025.",
  },
  // {
  //   title: "What happens at the end of the two-year program?",
  //   text: "???",
  // },
];

const FAQ: React.FC = () => {
  const [open, setOpen] = useState<number[]>([]);

  const toggleOpen = (index: number) => {
    setOpen((indexes) => (indexes.includes(index) ? indexes.filter((i) => i !== index) : [...indexes, index]));
  };

  return (
    <FAQSection id="faq" className="main-width">
      <h1 className="standard-title">FAQ</h1>

      <div className="list">
        {items.map((item, index) => (
          <div className={open.includes(index) ? "item open" : "item"} key={index}>
            <div className="header" onClick={() => toggleOpen(index)}>
              <h3>{item.title}</h3>
              <div className="toggle-btn">{open.includes(index) ? <Minus /> : <Plus />}</div>
            </div>
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </FAQSection>
  );
};

export default FAQ;
